import {
    WhyChooseUsCard,
    WhyChooseUsCardWrapper,
    WhyChooseUsIcon,
    WhyChooseUsWrapper,
} from 'widgets/why-choose-us/why-choose-us.style';
import LandingHeading from 'shared/ui/landing-heading';
import { useTranslation } from 'next-i18next';
import { Paragraph } from '@indriver/nova';
import { FC, ReactElement } from 'react';
import useWindowSize from 'shared/hooks/useWindowSize';

export interface IWhyChooseItem {
    id: number;
    title: string;
    text: string;
    icon: ReactElement;
}

interface IProps {
    list: IWhyChooseItem[];
}

const WhyChooseUs: FC<IProps> = ({ list }) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    return (
        <WhyChooseUsWrapper>
            <LandingHeading isTitle size={width <= 780 ? 'xxl' : 'xxxxl'} type='h2' text='landing.why_choose_us' />
            <WhyChooseUsCardWrapper>
                {list.map((item) => (
                    <WhyChooseUsCard key={item.id}>
                        <WhyChooseUsIcon>{item.icon}</WhyChooseUsIcon>
                        <LandingHeading size='xl' type='h3' text={item.title} />
                        <Paragraph>{t(item.text)}</Paragraph>
                    </WhyChooseUsCard>
                ))}
            </WhyChooseUsCardWrapper>
        </WhyChooseUsWrapper>
    );
};

export default WhyChooseUs;
